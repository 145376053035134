<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="fill">
    <mat-label>Auftragszeitraum</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Startdatum" formControlName="startDate">
      <input matEndDate placeholder="Enddatum" formControlName="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>


<app-generic-table [tableData]="allTableData" (tableInteraction)="tableInteraction($event)"></app-generic-table>


<button mat-raised-button color="primary" *ngIf="allTableData.data.length > 0" [disabled]="exportButtonDisabled"
        (click)="exportSelectedData()">Exportieren
</button>


<app-loading-spinner *ngIf="displayIsLoading"></app-loading-spinner>
