import {Component, OnInit} from '@angular/core';
import {DriverService} from '../../../services/driver.service';
import {TranslationService} from '../../../services/translate.service';
import {ACCOUNTING_TABLE_COLUMNS} from '../../../config/table-configurations';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {take} from "rxjs";
import {Accounting} from "../../../interfaces/accounting";
import {AdminRoutesService} from "../../../api/admin-routes.service";
import {formatDate} from "@angular/common";
import {TableInformation} from "../../../enum/table";
import {Generic_table, Generic_table_interaction} from "../../../interfaces/generic_table";

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

  /********
   Table
   *********/
  allTableData: Generic_table<Accounting> = {
    data: [],
    displayedColumns: ACCOUNTING_TABLE_COLUMNS,
    columnHeaders: this.translationService.translateStrings(ACCOUNTING_TABLE_COLUMNS),
    noDataMessage: 'Keine Daten vorhanden'
  }

  dateRangeForm: FormGroup;
  displayIsLoading = true;
  exportButtonDisabled = true;
  protected readonly tableInformation = TableInformation;

  constructor(
    private adminRoutesServices: AdminRoutesService,
    private driverService: DriverService,
    private fb: FormBuilder,
    private translationService: TranslationService
  ) {
    this.dateRangeForm = this.fb.group({
      startDate: [this.minusWeekToDate(new Date()), Validators.required],
      endDate: [new Date(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadWorktimes(this.dateRangeForm.value.startDate, this.dateRangeForm.value.endDate);
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        this.loadWorktimes(this.dateRangeForm.value.startDate, val);
      }
    });
  }

  tableInteraction(event: { action: Generic_table_interaction, value: any }) {
    if (event.action === Generic_table_interaction.checkboxMaster) {
      this.changeMaster(event.value);
    } else if (event.action === Generic_table_interaction.checkbox) {
      this.changeCheckbox(event.value?.rowIndex, event.value?.checked);
    }
    this.allTableData.data.filter(data => data.select).length > 0 ? this.exportButtonDisabled = false : this.exportButtonDisabled = true;
  }

  exportSelectedData(): void {
    const translatedHeaders = Object.values(this.allTableData.columnHeaders);
    this.driverService.exportPayrollData(this.allTableData.data.filter(data => data.select), translatedHeaders);
  }

  private changeCheckbox(index: number, checked: boolean): void {
    this.allTableData.data[index].select = checked;
  }

  private changeMaster(checked: boolean): void {
    this.allTableData.data.forEach(data => data.select = checked);
  }

  private minusWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() - 7);
    return result;
  }

  private loadWorktimes(startDate: Date, endDate: Date): void {
    this.displayIsLoading = true;

    const formattedStartDate = formatDate(startDate, 'dd.MM.yyyy', 'de-DE');
    const formattedEndDate = formatDate(endDate, 'dd.MM.yyyy', 'de-DE');

    this.adminRoutesServices.getAllWorktimeOfTimeRange(formattedStartDate, formattedEndDate)
      .pipe(take(1))
      .subscribe({
        next: (worktimes: any) => {
          this.displayIsLoading = false;
          this.allTableData.data = worktimes;
          this.allTableData.data.forEach(data => data.select = false);
        },
        error: () => {
          this.displayIsLoading = false;
        }
      });

  }
}
