import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {forkJoin, take} from "rxjs";
import {WebUser} from "../../../../interfaces/webuser";
import {MatPaginator} from "@angular/material/paginator";
import {PROFILE_ROLES_TABLE_COLUMNS} from "../../../../config/table-configurations";
import {WebusersService} from "../../../../api/webusers.service";
import {DatePipe} from "@angular/common";
import {TranslationService} from "../../../../services/translate.service";
import {UserRole} from "../../../../enum/user-roles";


@Component({
  selector: 'app-profile-roles',
  templateUrl: './profile-roles.component.html',
  styleUrls: ['./profile-roles.component.scss']
})
export class ProfileRolesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /********
   Table
   *********/
  tableData = new MatTableDataSource<WebUser>;
  displayedColumns: string[] = PROFILE_ROLES_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns);
  isLoading = true;
  rolesChanged = false;
  initialRoles = {};
  userRoles = [
    {value: UserRole.Admin, viewValue: 'Administrator'},
    {value: UserRole.User, viewValue: 'Benutzer'},
    {value: UserRole.Driver, viewValue: 'Fahrer'},
    {value: UserRole.CoDriver, viewValue: 'Beifahrer'}
  ];

  constructor(private webuserService: WebusersService, public datePipe: DatePipe, private translationService: TranslationService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.webuserService.getAllWebUser().pipe(take(1)).subscribe((users: WebUser[]) => {
      this.tableData.data = users;
      this.isLoading = false;
      this.initialRoles = users.reduce((acc, user) => {
        acc[user._id] = user.roleOfUser;
        return acc;
      }, {});
    });
  }

  ngAfterViewInit() {
    this.tableData.sort = this.sort;
    this.tableData.paginator = this.paginator;
  }

  onRoleChange(userId: string, newRole: UserRole.Admin | UserRole.User | UserRole.Driver | UserRole.CoDriver) {
    this.tableData.data.forEach((user: WebUser) => {
      if (user._id === userId) {
        user.roleOfUser = newRole;
      }
    });
    this.rolesChanged = !this.tableData.data.every(user => user.roleOfUser === this.initialRoles[user._id]);
  }

  saveChanges() {
    const changedUsers = this.getChangedUsers();
    forkJoin(
      changedUsers.map(user => this.webuserService.updateRoleOfUser(user.id, user.roleOfUser).pipe(take(1)))
    ).subscribe(() => {
      window.location.reload();
    });
  }

  getChangedUsers() {
    return this.tableData.data.filter(user => user.roleOfUser !== this.initialRoles[user._id])
      .map(user => ({id: user._id, roleOfUser: user.roleOfUser}));
  }
}
