import {AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {MatPaginator} from "@angular/material/paginator";
import {OVERVIEW_RIDES_TABLE_COLUMNS} from "../../../config/table-configurations";
import {TranslationService} from "../../../services/translate.service";
import {JobService} from "../../../api/job.service";
import {filter, Subject, switchMap, take, takeUntil, tap} from "rxjs";
import {Job} from "../../../interfaces/job";
import {WebUserStore} from "../../../stores/webUser.store";
import {WebUser} from "../../../interfaces/webuser";
import {UserRole} from "../../../enum/user-roles";
import {TableInformation} from "../../../enum/table";

@Component({
  selector: 'app-overview-jobs',
  templateUrl: './overview-jobs.component.html',
  styleUrls: ['./overview-jobs.component.scss']
})
export class OverviewJobsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$ = new Subject<void>();

  webUser: WebUser;
  rowData: Job[]
  earliestDate: Date | null = null;
  latestDate: Date | null = null;
  tooltipText: string = "Kein Auftrag gefunden";

  drivernameOptions: string[] = [];
  selectedDrivernames = new FormControl([]);

  protected readonly tableInformation = TableInformation;

  /********
   Table
   *********/
  tableData = new MatTableDataSource<Job>;
  displayedColumns: string[] = OVERVIEW_RIDES_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns);
  dateRangeForm: FormGroup;

  displayLoading = true;

  constructor(private webUserStore: WebUserStore, private jobService: JobService, public datePipe: DatePipe, private fb: FormBuilder, private translationService: TranslationService) {
    this.dateRangeForm = this.fb.group({
      startDate: [new Date(), Validators.required],
      endDate: [this.addWeekToDate(new Date()), Validators.required],
      drivername: ['']
    });
  }

  ngOnInit(): void {
    this.webUserStore.currentWebUser$.pipe(
      takeUntil(this.destroy$),
      tap(webUser => {
        this.webUser = webUser;
      }),
      filter(webUser => webUser !== null),
      switchMap(() => this.jobService.getAllJobs().pipe(take(1)))
    ).subscribe((jobs: Job[]) => {
      jobs.sort((a, b) => new Date(b.orderdate).getTime() - new Date(a.orderdate).getTime());
      if (this.webUser.roleOfUser === UserRole.Admin) {
        this.tableData.data = jobs;
        this.rowData = jobs;
      } else {
        this.tableData.data = jobs.filter(job => job.driverno === this.webUser.driverno);
        this.rowData = jobs.filter(job => job.driverno === this.webUser.driverno);
      }
      this.drivernameOptions = [...new Set(jobs.map(job => job.drivername))];
      if (this.tableData.data.length > 0) {
        this.findEarliestAndLatestDate(jobs);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
  }


  private addWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + 7);
    return result;
  }

  filterTable(): void {
    this.displayLoading = true;
    const startDate = new Date(this.dateRangeForm.value.startDate);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.dateRangeForm.controls['endDate'].value);
    endDate.setHours(23, 59, 59, 999);
    const selectedDrivernames = this.selectedDrivernames.value;

    this.tableData.data = this.rowData;

    const filteredData = this.tableData.data.filter(row => {
      const day = new Date(row.orderdate);
      const matchesDrivername = selectedDrivernames.length === 0 || selectedDrivernames.includes(row.drivername);
      return day >= startDate && day <= endDate && matchesDrivername;
    });
    this.tableData.data = filteredData;
    this.displayLoading = false;
  }


  private findEarliestAndLatestDate(jobs: Job[]) {
    const dates = jobs.map(job => new Date(job.orderdate));
    this.earliestDate = dates.reduce((earliest, current) => current < earliest ? current : earliest);
    this.latestDate = dates.reduce((latest, current) => current > latest ? current : latest);
    this.tooltipText = `Der früheste Auftrag ist am ${this.datePipe.transform(this.earliestDate, 'dd.MM.yyyy')} und der letzte Auftrag am ${this.datePipe.transform(this.latestDate, 'dd.MM.yyyy')}`;
    if (this.earliestDate && this.latestDate) {
      this.dateRangeForm.patchValue({
        startDate: this.earliestDate,
        endDate: this.latestDate
      });
    }

    this.setFilter();
    this.displayLoading = false;
  }

  private setFilter(): void {
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        this.filterTable();
      }
    });

    this.selectedDrivernames.valueChanges.subscribe(() => {
      this.filterTable();
    });
  }
}
