import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {take} from "rxjs";
import {AuthService} from "../../../api/auth.service";
import {UserRoleTranslation} from "../../../enum/user-roles-translation";
import {Profile} from "../../../interfaces/profile";


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup
  passwordIsVisible = false;
  loadingSpinner = false;
  registerErrorMessage: string;
  sendEmailMessage: string;


  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(7)]],
      newsletter: [false],
    });
  }

  togglePasswordVisibility(): void {
    this.passwordIsVisible = !this.passwordIsVisible;
  }

  register(): void {
    this.loadingSpinner = true;
    const newUser: Profile = {
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName,
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      userRole: UserRoleTranslation.User,
      isVerified: false,
      newsletter: this.registerForm.value.newsletter,
    }
    this.authService.registerUser(newUser).pipe(take(1)).subscribe((backendAnswer: any) => {
      if (backendAnswer?.success) {
        this.sendEmailMessage = backendAnswer.message
        this.loadingSpinner = false;
      } else {
        this.loadingSpinner = false;
        this.registerErrorMessage = backendAnswer.message
      }
    });
  }

  backToLogin() {
    this.router.navigate(['/login'])
  }

}
