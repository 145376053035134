import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {OVERVIEW_DRIVER_TABLE_COLUMNS} from "../../../config/table-configurations";
import {TranslationService} from "../../../services/translate.service";
import {MatPaginator} from "@angular/material/paginator";
import {WebusersService} from "../../../api/webusers.service";
import {WebUser} from "../../../interfaces/webuser";
import {take} from "rxjs";
import {TableInformation} from "../../../enum/table";

@Component({
  selector: 'app-overview-driver',
  templateUrl: './overview-driver.component.html',
  styleUrls: ['./overview-driver.component.scss']
})
export class OverviewDriverComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  protected readonly tableInformation = TableInformation;

  /********
   Table
   *********/
  tableData = new MatTableDataSource();
  displayedColumns: string[] = OVERVIEW_DRIVER_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns);
  displayLoading = true;


  constructor(private webuserService: WebusersService, private _liveAnnouncer: LiveAnnouncer, private router: Router, public datePipe: DatePipe, private translationService: TranslationService) {
  }

  ngOnInit() {
    this.webuserService.getAllWebUser().pipe(take(1)).subscribe((users: WebUser[]) => {
      this.tableData.data = users.filter(user => user.roleOfUser === 'driver' || user.roleOfUser === 'codriver');
      this.displayLoading = false;
    });
  }

  ngAfterViewInit() {
    this.tableData.sort = this.sort;
    this.tableData.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort):
    void {
    if (sortState.direction === 'asc'
    ) {
      this._liveAnnouncer.announce(`Sorted ascending`);
    } else if (sortState.direction === 'desc') {
      this._liveAnnouncer.announce(`Sorted descending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  searchDriver(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();
  }

  openDriverDetails(driver: WebUser): void {
    this.router.navigate(['/driver-details', `${driver.lastname}${driver.firstname}`], {state: {driverId: driver._id}});
  }

}
