export enum TableInformation {
  Approved = 'approved',
  BreakTime = 'breakTime',
  BreakTimeInMinutes = 'breakTimeInMinutes',
  BufferTime = 'bufferTime',
  City = 'city',
  Company = 'company',
  Date = 'date',
  Day = 'day',
  Description = 'description',
  Destination = 'destination',
  Driver = 'driver',
  DriverName = 'drivername',
  DriverNo = 'driverno',
  DrivingTime = 'drivingTime',
  DrivingTimeInMinutes = 'drivingTimeInMinutes',
  EmployeeNo = 'employeeno',
  Event = 'event',
  FirstName = 'firstname',
  IdleTime = 'idleTime',
  JobNo = 'jobNo',
  LastName = 'lastname',
  LastUpdate = 'lastUpdate',
  LicensePlate = 'licensePlate',
  Manufacturer = 'manufacturer',
  ModelName = 'modelName',
  Name = 'name',
  Name1 = 'name1',
  Number = 'number',
  ObjectName = 'objectname',
  ObjectNo = 'objectno',
  OrderDate = 'orderdate',
  OrderId = 'orderid',
  OrderState = 'orderstate',
  OrderText = 'ordertext',
  Payment = 'payment',
  PlannedArrivalTime = 'planned_arrival_time',
  Position = 'position',
  Reason = 'reason',
  RoleOfUser = 'roleOfUser',
  SalaryType = 'salaryType',
  Select = 'select',
  Status = 'status',
  Street = 'street',
  WaitingTime = 'waitingTime',
  WaitingTimeInMinutes = 'waitingTimeInMinutes',
  WorkTime = 'workTime',
  Zip = 'zip',
}
