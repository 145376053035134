<table mat-table [dataSource]="tableData.data" *ngIf="tableData.data?.length > 0; else noDataTemplate"
       class="mat-elevation-z8">
  <ng-container *ngFor="let column of tableData.displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="tableInformation.Select">
          <mat-checkbox [checked]="masterCheckbox"
                        (change)="tableInteraction.emit({action: actionEnum.checkboxMaster, value: $event.checked})"></mat-checkbox>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{ tableData.columnHeaders[column] }}</span>
        </ng-container>
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let element; let rowIndex = index">
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="tableInformation.OrderDate">
          {{ element[column] | date: 'dd.MM.yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="tableInformation.PlannedArrivalTime">
          {{ element[column] | date: 'dd.MM.yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="tableInformation.BreakTime">
          {{ element[column] | industrialMinutes }} Std.
        </ng-container>
        <ng-container *ngSwitchCase="tableInformation.IdleTime">
          {{ element[column] | industrialMinutes }} Std.
        </ng-container>
        <ng-container *ngSwitchCase="tableInformation.WorkTime">
          {{ element[column] | industrialMinutes }} Std.
        </ng-container>
        <ng-container *ngSwitchCase="tableInformation.Select">
          <mat-checkbox [checked]="element.select"
                        (change)="tableInteraction.emit({action: actionEnum.checkbox, value: {rowIndex, checked: $event.checked}}); checkMasterCheckbox()"></mat-checkbox>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ element[column] }}
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="tableData.displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: tableData.displayedColumns;"></tr>
</table>

<ng-template #noDataTemplate>
  <div>{{ tableData.noDataMessage }}</div>
</ng-template>
