<table mat-table [dataSource]="tableData">

  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef> {{ columnHeaders[column] }} </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="column === 'roleOfUser'; else defaultCell">
        <mat-form-field appearance="outline">
          <mat-select [(value)]="element[column]" (selectionChange)="onRoleChange(element._id, $event.value)">
            <mat-option *ngFor="let role of userRoles" [value]="role.value">{{ role.viewValue }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-template #defaultCell>
        {{ element[column] }}
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
</table>

<div class="action-button">
  <button mat-raised-button color="primary" [disabled]="!rolesChanged" (click)="saveChanges()">Speichern</button>
</div>

<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
