import { Injectable } from '@angular/core';
import { Accounting } from "../interfaces/accounting";

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  constructor() { }

  exportPayrollData(data: Accounting[], headers: string[]): void {
    const csvData = this.convertToCSV(data, headers);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'payroll-data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private convertToCSV(data: any[], headers: string[]): string {
    const array = [headers].concat(data.map(item => Object.values(item)));
    return array.map(it => it.toString()).join('\n');
  }
}
