<div class="date-range">
  <form [formGroup]="dateRangeForm">
    <mat-form-field appearance="fill">
      <mat-label>Zeitraum Arbeitszeiten</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Startdatum" formControlName="startDate">
        <input matEndDate placeholder="Enddatum" formControlName="endDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-icon matTooltip="{{this.tooltipText}}">info</mat-icon>

    <mat-form-field appearance="fill">
      <mat-label>Fahrername</mat-label>
      <mat-select [formControl]="selectedDrivernames" multiple>
        <mat-option *ngFor="let drivername of drivernameOptions" [value]="drivername">
          {{ drivername }}
        </mat-option>
      </mat-select>
    </mat-form-field>


  </form>
</div>
<ng-container>
  <table *ngIf="tableData?.data.length > 0; else noData" mat-table [dataSource]="tableData">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef> {{ columnHeaders[column] }}</th>
      <td mat-cell *matCellDef="let ride">
        <ng-container *ngIf="column === tableInformation.OrderDate || column === tableInformation.PlannedArrivalTime; else defaultCell">
          {{ this.datePipe.transform(ride[column], 'dd.MM.yyyy') }}
        </ng-container>
        <ng-template #defaultCell>
          {{ ride[column] }}
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [length]="this.tableData.data.length" [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
  </mat-paginator>
</ng-container>

<ng-template #noData>
  <div>Leider liegen keine Arbeitszeiten vor</div>
</ng-template>

<app-loading-spinner *ngIf="displayLoading"></app-loading-spinner>
