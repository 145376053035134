import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WebUser} from "../../../interfaces/webuser";
import {WebUserStore} from "../../../stores/webUser.store";
import {WorktimeNotificationService} from "../../../api/worktime-notification.service";
import {Subject, take, takeUntil} from "rxjs";
import {WorktimeNotification} from "../../../interfaces/worktime_notification";
import {Reason} from "../../../enum/reason";


@Component({
  selector: 'app-worktime-notification',
  templateUrl: './worktime-notification.component.html',
  styleUrls: ['./worktime-notification.component.scss']
})
export class WorktimeNotificationComponent implements OnInit, OnDestroy {
  workTimeNotification: FormGroup;
  destroy$ = new Subject<void>();
  webUser: WebUser;
  reasons = Object.values(Reason);



  constructor(private fb: FormBuilder, private webUserStore: WebUserStore, private workTimeNotificationService: WorktimeNotificationService) {
    this.workTimeNotification = this.fb.group({
      jobNumber: ['', Validators.required],
      description: ['', Validators.required],
      addedWorktime: ['', Validators.required],
      reason: ['', Validators.required],
      day: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.webUserStore.currentWebUser$.pipe(takeUntil(this.destroy$)).subscribe((webUser: WebUser) => {
      this.webUser = webUser;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    if (this.workTimeNotification.valid) {
      const formValues = this.workTimeNotification.value;
      const worktimeNotification: WorktimeNotification = {
        day: new Date(formValues.day),
        driverNo: this.webUser.driverno,
        jobNo: formValues.jobNumber,
        reason: formValues.reason,
        description: formValues.description,
        addedWorktime: formValues.addedWorktime,
        approved: false
      };
      this.workTimeNotificationService.createWorktimeNotification(worktimeNotification).pipe(take(1)).subscribe();
      alert('Meldung abgesendet!');
      this.workTimeNotification.reset();
      //windows.location.reload();
    }
  }
}
