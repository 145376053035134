import {Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {TableInformation} from "../../../enum/table";
import {DatePipe} from "@angular/common";
import {Generic_table, Generic_table_interaction} from "../../../interfaces/generic_table";
import {Selectable} from "../../../interfaces/selectable";


@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent<T extends Selectable> {
  @Input() tableData: Generic_table<T> = {data: [], displayedColumns: [], columnHeaders: {}, noDataMessage: ''};

  @Output() tableInteraction = new EventEmitter<{ action: Generic_table_interaction, value: any }>();

  masterCheckbox: boolean = false;

  protected readonly actionEnum = Generic_table_interaction;

  protected readonly tableInformation = TableInformation;

  constructor(public datePipe: DatePipe) {
  }

  checkMasterCheckbox(): void {
    this.masterCheckbox = this.tableData.data.every(data => data.select);
  }
}
