<ng-container *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</ng-container>

<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="fill">
    <mat-label>Auftragszeitraum</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Startdatum" formControlName="startDate">
      <input matEndDate placeholder="Enddatum" formControlName="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>

<table mat-table [dataSource]="tableData" class="mat-elevation-z8">
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef>
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="tableInformation.Select">
          <mat-checkbox [checked]="masterCheckbox" (change)="changeMaster($event.checked)"></mat-checkbox>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{ columnsHeaders[column] }}</span>
        </ng-container>
      </ng-container>
    </th>
    <td mat-cell *matCellDef="let driver">
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="tableInformation.Select">
          <mat-checkbox [checked]="driver.select" (change)="changeCheckbox(driver, $event.checked)"></mat-checkbox>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{ driver[column] }}</span>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<button mat-raised-button color="primary" (click)="exportSelectedData()">Exportieren</button>
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
