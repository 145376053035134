<!--<mat-form-field class="search-driver">
  <mat-label>Fahrer suchen</mat-label>
  <input matInput (keyup)="searchDriver($event)" #input>
</mat-form-field>-->


<table mat-table [dataSource]="tableData" matSort (matSortChange)="announceSortChange($event)">

  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ columnHeaders[column] }}</th>
    <td mat-cell *matCellDef="let element" (click)="openDriverDetails(element)">
        {{ element[column] }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

<app-loading-spinner *ngIf="displayLoading"></app-loading-spinner>
