import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ProfileFrontendService} from '../services/profile-frontend.service';

@Injectable({
  providedIn: 'root'
})
export class AdminRoutesService {

  constructor(private httpClient: HttpClient, private profileFrontendService: ProfileFrontendService) {
  }


  getAllWorktimeOfTimeRange(startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/accounting`, {
      headers: { token: this.profileFrontendService.getUserInformationFromStorage('token') },
      params: { startDate: startDate, endDate: endDate }
    });
  }

  syncDrivers() {
    return this.httpClient.post(`${environment.apiUrl}/syncdrivers`, {});
  }

  syncOrders() {
    return this.httpClient.post(`${environment.apiUrl}/syncorder`, {});
  }

  syncAllOrders() {
    return this.httpClient.post(`${environment.apiUrl}/syncorders`, {});
  }
}
