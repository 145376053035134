import {TableInformation} from '../enum/table';

export const ACCOUNTING_TABLE_COLUMNS = [
  TableInformation.Select,
  TableInformation.DriverNo,
  TableInformation.FirstName,
  TableInformation.LastName,
  TableInformation.BreakTime,
  TableInformation.IdleTime,
  TableInformation.WorkTime
];

export const OVERVIEW_DRIVER_TABLE_COLUMNS = [
  TableInformation.DriverNo,
  TableInformation.FirstName,
  TableInformation.LastName,
  TableInformation.Zip,
  TableInformation.City,
  TableInformation.Street,
  TableInformation.Company
];

export const OVERVIEW_RIDES_TABLE_COLUMNS = [
  TableInformation.OrderDate,
  TableInformation.OrderId,
  TableInformation.OrderText,
  TableInformation.DriverName,
  TableInformation.Destination,
  TableInformation.PlannedArrivalTime,
  TableInformation.ObjectName,
  TableInformation.DriverNo,
  TableInformation.ObjectNo
];

export const WORKTIME_ADMIN_TABLE_COLUMNS = [
  TableInformation.BreakTime,
  TableInformation.Day,
  TableInformation.DriverNo,
  TableInformation.FirstName,
  TableInformation.LastName,
  TableInformation.IdleTime,
  TableInformation.WorkTime
];

export const WORKTIME_NOTIFICATION_ADMIN_TABLE_COLUMNS = [
  TableInformation.Day,
  TableInformation.DriverNo,
  TableInformation.JobNo,
  TableInformation.Reason,
  TableInformation.Description,
  TableInformation.Approved
];

export const PROFILE_ROLES_TABLE_COLUMNS = [
  TableInformation.FirstName,
  TableInformation.LastName,
  TableInformation.Company,
  TableInformation.Street,
  TableInformation.BufferTime,
  TableInformation.EmployeeNo,
  TableInformation.SalaryType,
  TableInformation.RoleOfUser
];
