import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private translations: { [key: string]: string } = {
    approved: 'Genehmigt',
    breakTime: 'Pausenzeit',
    breakTimeInMinutes: 'Pausenzeit',
    bufferTime: 'Karenzzeit',
    city: 'Stadt',
    company: 'Firma',
    date: 'Datum',
    day: 'Tag',
    description: 'Beschreibung',
    destination: 'Ziel',
    driver: 'Fahrer',
    drivername: 'Fahrer',
    driverno: 'Fahrer-Nummer',
    drivingTime: 'Fahrtzeit',
    drivingTimeInMinutes: 'Fahrzeit',
    employeeno: 'Personalnummer',
    event: 'Ereignis',
    firstname: 'Vorname',
    idleTime: 'Leerlaufzeit',
    jobNo: 'Auftragsnummer',
    lastname: 'Nachname',
    lastUpdate: 'Letztes Update',
    licensePlate: 'Kennzeichen',
    manufacturer: 'Hersteller',
    modelName: 'Modell',
    name: 'Name',
    name1: 'Name',
    number: 'Nummer',
    objectname: 'Kennzeichen',
    objectno: 'Fahrzeugnummer',
    orderdate: 'Datum',
    orderid: 'Auftragsnummer',
    orderstate: 'Auftragsstatus',
    ordertext: 'Auftragstext',
    payment: 'Lohnart',
    planned_arrival_time: 'Geplante Ankunftszeit',
    position: 'Position',
    reason: 'Grund',
    roleOfUser: 'Rolle',
    salaryType: 'Lohnart',
    select: 'Select',
    status: 'Status',
    street: 'Straße',
    waitingTime: 'Wartezeit',
    waitingTimeInMinutes: 'Rufbereitschaft',
    workTime: 'Arbeitszeit',
    zip: 'PLZ',
  };

  constructor() {
  }

  translateStrings(keys: string[]): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    keys.forEach(key => {
      result[key] = this.translations[key] || '';
    });
    return result;
  }
}
