import { Component, OnInit } from '@angular/core';
import { DriverService } from '../../../services/driver.service';
import { TranslationService } from '../../../services/translate.service';
import { ACCOUNTING_TABLE_COLUMNS } from '../../../config/table-configurations';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { take } from "rxjs";
import { Accounting } from "../../../interfaces/accounting";
import { AdminRoutesService } from "../../../api/admin-routes.service";
import { formatDate } from "@angular/common";
import { TableInformation } from "../../../enum/table";

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

  /********
   Table
   *********/
  tableData = new MatTableDataSource<Accounting>();
  displayedColumns: string[] = ACCOUNTING_TABLE_COLUMNS;
  columnsHeaders = this.translationService.translateStrings(this.displayedColumns);
  masterCheckbox: boolean = false;
  dateRangeForm: FormGroup;
  isLoading = true;
  protected readonly tableInformation = TableInformation;

  constructor(
    private adminRoutesServices: AdminRoutesService,
    private driverService: DriverService,
    private fb: FormBuilder,
    private translationService: TranslationService
  ) {
    this.dateRangeForm = this.fb.group({
      startDate: [this.minusWeekToDate(new Date()), Validators.required],
      endDate: [new Date(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadWorktimes(this.dateRangeForm.value.startDate, this.dateRangeForm.value.endDate);
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        this.loadWorktimes(this.dateRangeForm.value.startDate, val);
      }
    });
  }

  exportSelectedData(): void {
    const translatedHeaders = Object.values(this.columnsHeaders);
    this.driverService.exportPayrollData(this.tableData.data.filter(data => data.select), translatedHeaders);
  }

  changeCheckbox(driver: any, checked: boolean): void {
    driver.select = checked;
    if (this.tableData.data.filter(data => data.select).length < this.tableData.data.length) {
      this.masterCheckbox = false;
    } else if (this.tableData.data.filter(data => data.select).length === this.tableData.data.length) {
      this.masterCheckbox = true;
    }
  }

  changeMaster(checked: boolean): void {
    this.masterCheckbox = checked;
    this.tableData.data.forEach(data => data.select = checked);
  }

  private minusWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() - 7);
    return result;
  }

  private loadWorktimes(startDate: Date, endDate: Date): void {
    this.isLoading = true;

    const formattedStartDate = formatDate(startDate, 'dd.MM.yyyy', 'de-DE');
    const formattedEndDate = formatDate(endDate, 'dd.MM.yyyy', 'de-DE');

    this.adminRoutesServices.getAllWorktimeOfTimeRange(formattedStartDate, formattedEndDate).pipe(take(1)).subscribe((worktimes: any) => {
      this.isLoading = false;
      this.tableData.data = worktimes;

    }, (error) => {
      this.isLoading = false;
    });

  }
}
