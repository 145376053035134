import { Selectable } from './selectable';

export interface Generic_table<T> {
  data: T[];
  displayedColumns: string[];
  columnHeaders: { [key: string]: string };
  noDataMessage?: string;
}

export enum Generic_table_interaction {
  checkboxMaster = 'checkboxMaster',
  checkbox = 'checkbox',
}

