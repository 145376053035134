<div *ngIf="isLoggedIn(); else login">
  <mat-toolbar color="primary" class="mat-elevation-z8">
    <div>
      <!--  <button
          mat-icon-button
          *ngIf="sidenav.mode === 'side'"
          (click)="sidenav.toggle()"
        >
          <mat-icon *ngIf="!sidenav.opened"> menu</mat-icon>
          <mat-icon *ngIf="sidenav.opened"> close</mat-icon>
        </button>-->
      <button *ngIf="webUserIsAdmin" mat-flat-button color="primary" (click)="navigate('overview-driver')"
              [ngClass]="{'active': isActive('/overview-driver')}">Fahrer
      </button>
      <button mat-flat-button color="primary" (click)="navigate('overview-jobs')"
              [ngClass]="{'active': isActive('/overview-jobs')}">Aufträge
      </button>
      <button *ngIf="webUserIsAdmin" mat-flat-button color="primary" (click)="navigate('accounting')"
              [ngClass]="{'active': isActive('/accounting')}">Buchhaltung
      </button>
      <button mat-flat-button color="primary" (click)="navigate('worktime-notification')"
              [ngClass]="{'active': isActive('/worktime-notification')}">Zeitmeldung
      </button>
      <button mat-flat-button color="primary" (click)="navigate('worktime')"
              [ngClass]="{'active': isActive('/worktime')}">Arbeitszeiten
      </button>
      <button *ngIf="webUserIsAdmin" mat-flat-button color="primary" (click)="navigate('worktime-notification-admin')"
              [ngClass]="{'active': isActive('/worktime-notification-admin')}">Offene Zeitmeldungen
      </button>

    </div>


    <div>Taxi Hess-Arbeitszeiterfassung</div>

    <div>
      <ng-container *ngIf="webUserIsAdmin">
        <button matTooltip="Einstellungen" mat-button [matMenuTriggerFor]="menu">
          <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="navigate('profile-roles')">Benutzerverwaltung</button>
        </mat-menu>
      </ng-container>


      <button mat-button [matMenuTriggerFor]="menuPerson">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menuPerson="matMenu">
        <button mat-menu-item (click)="navigate('profile')">Profil</button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>


    </div>
  </mat-toolbar>


  <mat-sidenav-container>
    <!--    <mat-sidenav mode="side" #sidenav="matSidenav" class="mat-elevation-z8">
          <img
            class="avatar mat-elevation-z8"
            src="/assets/img/logo.png"
          />

          <h4 class="name">Julian</h4>
          <p class="designation">Software Engineer</p>

          <mat-divider></mat-divider>

          <ng-template ngFor let-item [ngForOf]="items" let-i="index">
            <button mat-button class="menu-button" routerLink="{{item.url}}">
              <mat-icon>{{item.icon}}</mat-icon>
              <span>{{item.name}}</span>
            </button>
          </ng-template>

          <mat-divider></mat-divider>

          <button mat-button class="menu-button" routerLink="/settings">
            <mat-icon>help</mat-icon>
            <span>Settings</span>
          </button>
        </mat-sidenav>-->
    <mat-sidenav-content>
      <div class="content mat-elevation-z8">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
<ng-template #login>
  <router-outlet></router-outlet>
</ng-template>
