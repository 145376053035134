import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'industrialMinutes'
})
export class IndustrialMinutesPipe implements PipeTransform {

  transform(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const industrialMinutes = (remainingMinutes / 60).toFixed(2).replace('.', ',');
    return `${hours},${industrialMinutes.split(',')[1]}`;
  }
}
